
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Axios from '../../services/api'

import GetAppIcon from '@material-ui/icons/GetApp';


import { makeStyles } from '@material-ui/core/styles';


import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    Button
} from '@material-ui/core';




const DialogOrdem = ({ ordem, getSignedUrl }) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleProducao = async (id) => {

        try {


            const novoStatus = 'Produção'; // Coloque aqui o novo status desejado

            // Realiza a requisição PUT para atualizar o status da ordem
            await Axios.put(`/ordem/${id}`, { situation: novoStatus });


        } catch (error) {
            // Em caso de erro na requisição, você pode tratar o erro aqui
            console.error('Erro ao atualizar o status da ordem:', error);
        }
    }
    const handleFinalizar = async (id) => {

        try {


            const novoStatus = 'finalizado'; // Coloque aqui o novo status desejado

            // Realiza a requisição PUT para atualizar o status da ordem
            await Axios.put(`/ordem/${id}`, { situation: novoStatus });


        } catch (error) {
            // Em caso de erro na requisição, você pode tratar o erro aqui
            console.error('Erro ao atualizar o status da ordem:', error);
        }
    }

    return (
        <>
            <Fab color="primary" aria-label="add" onClick={() => handleClickOpen()}>
                <AddIcon />
            </Fab>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                <DialogContent>
                    <div>
                        <Table>
                            <TableHead style={{ backgroundColor: '#0075B6' }}>
                                <TableRow>

                                    <TableCell colSpan={2} align="center"><h3>{ordem.servico}</h3></TableCell>
                                  

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow>
                                    <TableCell >
                                        especificações: {ordem.atributo}


                                    </TableCell>
                                    <TableCell align='center'>
                                        {new Date(ordem.createdAt).toLocaleString()}


                                    </TableCell>

                                </TableRow>
                                <TableRow>

                                    <TableCell >
                                        observações: {ordem.obs}

                                    </TableCell>
                                </TableRow>

                                <TableRow>

                                    <TableCell>
                                        Cliente: {ordem.cliente}

                                    </TableCell>


                                </TableRow>
                                <TableRow>

                                    <TableCell>
                                        Uploads:

                                    </TableCell>
                                    <TableCell>
                                        <Grid container spacing={1}>
                                            {ordem?.uploads.map((e, index) => {

                                                return (

                                                    <Grid item key={index}>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            size='large'
                                                            style={{ maxWidth: '300px' }}
                                                            onClick={() => {
                                                                try {
                                                                    const signedUrl = `${process.env.REACT_APP_BACKEND_URL}/public/${e.fileName}`;
                                                                    window.open(signedUrl, '_blank'); // Abre a URL assinada em uma nova aba
                                                                } catch (error) {
                                                                    console.error('Erro ao obter URL assinada:', error);
                                                                }
                                                            }}><GetAppIcon />
                                                            <div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                                <span>{e.fileName.substring(8)}</span>
                                                            </div>
                                                        </Button>
                                                    </Grid>

                                                )
                                            })}
                                        </Grid>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                <TableCell >

                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#9DD09B' }}
                                        onClick={() => {
                                            if (ordem.situation === 'pendente') {
                                                handleProducao(ordem.id)
                                            }
                                            else {

                                                handleFinalizar(ordem.id)
                                            }


                                        }}
                                    >
                                        {ordem.situation === 'pendente' ? 'Em produção' : 'Finalizar Ordem'}
                                    </Button>

                                </TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>


                <DialogActions>

                    <Button onClick={handleClose} color="primary" autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialogOrdem;